import { TYPES } from "../actions/actionTypes";

const initialState: CrudState = []

const crudReducer = (
  state: CrudState = initialState,
  action: CrudAction
) => {
  switch (action.type) {
    case TYPES.CRUD.GET:
      return action.crud;
    case TYPES.CRUD.ADD:
      return [...state, action.crud];
    case TYPES.CRUD.UPDATE:
      return state.map((crud: any) => {
        if (crud.id === action.crud.id) {
          return action.crud;
        }
        return crud;
      })
    case TYPES.CRUD.DISABLE:
      return state.filter((s: any) => s.id !== action.crud) && window.location.reload();
    case TYPES.CRUD.DELETE:
      return state.filter((s: any) => s.id !== action.crud) && window.location.reload();
  }
  return state;
}

export default crudReducer
