import { Grid, Typography, Button, Box } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { currentClientAction } from "../redux/actions/CurrentClientActions";
import { IUser } from "../redux/Interfaces/typeUser";
import { IRedux } from "../redux/reducers";
import LiensInternes from "../services/LiensInternes";
import "./ChoixClient.scss";

function ChoixClient() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { addCurrentClient } = bindActionCreators(
		currentClientAction,
		dispatch
	);
	const user: IUser = useSelector((state: IRedux) => state?.userReduxState);

	const filteredClients = user.client?.filter((client: any) => !client.delete);

	return (
		<div className="container-main">
			<h1 className="h1-title">Choisissez votre client</h1>
			<div className="container-background-transparent">
				<Grid
					container
					rowSpacing={1}
					columnSpacing={{ xs: 1, sm: 2, md: 3 }}
				>
					{filteredClients?.map((client: any) => (
						<Grid xs={6} key={client.id}>
							<Box
								sx={{
									padding: "2vh",
								}}
							>
								<Button
									variant="contained"
									style={{
										textAlign: "center",
										width: "100%",
										padding: "2vh",
										borderRadius: "20px",
									}}
									onClick={() => {
										addCurrentClient(client);
										navigate(
											LiensInternes.ADMIN.BASE +
												LiensInternes.ADMIN
													.TABLEAU_DE_BORD
										);
									}}
								>
									<Typography variant="h5">
										{client.nom}
									</Typography>
								</Button>
							</Box>
						</Grid>
					))}
				</Grid>
			</div>
		</div>
	);
}

export default ChoixClient;
