export const toDateString = (date) => {
	let d = new Date(date);
	return (
		(d.getDate() < 10 ? "0" : "") +
		d.getDate() +
		"/" +
		(d.getMonth() < 10 ? "0" : "") +
		(d.getMonth() + 1) +
		"/" +
		d.getFullYear()
	);
};

export const toDateStringWithHour = (date) => {
	let d = new Date(date);
	return (
		(d.getDate() < 10 ? "0" : "") +
		d.getDate() +
		"/" +
		(d.getMonth() < 10 ? "0" : "") +
		(d.getMonth() + 1) +
		"/" +
		d.getFullYear() +
		" de " +
		(d.getHours() < 10 ? "0" : "") +
		d.getHours() +
		":" +
		(d.getMinutes() < 10 ? "0" : "") +
		d.getMinutes() 
		// +
		// ":" +
		// (d.getSeconds() < 10 ? "0" : "") +
		// d.getSeconds()
	);
};

export const toHourString = (date) => {
	let d = new Date(date);
	return (
		(d.getHours() < 10 ? "0" : "") +
		d.getHours() +
		":" +
		(d.getMinutes() < 10 ? "0" : "") +
		d.getMinutes() 
		// +
		// ":" +
		// (d.getSeconds() < 10 ? "0" : "") +
		// d.getSeconds()
	);
};