import CustomAxios from "../../services/Interceptor/CustomAxios";
import { TYPES } from "./actionTypes";
import { routesApi } from "../../services/RoutesApi"
import { DispatchRdv, IRdv, RdvAction } from "../Interfaces/typeRdv";
import { Dispatch } from "redux";

let baseUrl = process.env.REACT_APP_URL_BACK;
const getRdvByLieu = (id: number) => {
  return (dispatch: DispatchRdv) => {
    CustomAxios
      .get(baseUrl + routesApi.GET_RDV_BY_LIEU + "?lieu_id=" + id)
      .then(function (response) {
        if (response.status === 200) {
          const action: RdvAction = {
            type: TYPES.RDV.GET,
            rdv: response.data
          }
          dispatch(action)
        }
      })
  }
}

const validerAbsenceClient = (creneauMissed: any) => {

  return (dispatch: Dispatch) => {
    CustomAxios
      .post(baseUrl + routesApi.ABSENCE_CLIENT_RDV, creneauMissed)
      .then(function (response) {
        if (response.status === 200 && response.data.statusCode !== 403) {
          const data: IRdv = response.data
          const action: RdvAction = {
            type: TYPES.RDV.ABSENCE_RDV,
            rdv: data
          }
          dispatch(action)
        }
      })
  }
}

const rdvHonorer = (creneauConfirm: any) => {

  return (dispatch: Dispatch) => {
    CustomAxios
      .post(baseUrl + routesApi.VALIDER_RDV, creneauConfirm)
      .then(function (response) {
        if (response.status === 200 && response.data.statusCode !== 403) {
          const data: IRdv = response.data
          const action: RdvAction = {
            type: TYPES.RDV.ABSENCE_RDV,
            rdv: data
          }
          dispatch(action)
        }
      })
  }
}

const checkRdvCommande = (idClient: number, idPeriodeIntervention: number) => {
  return (dispatch: DispatchRdv) => {
    CustomAxios
      .get(baseUrl + routesApi.CHECK_RDV_COMMANDE + `?client_id=${idClient}` + `&periode_intervention_id=${idPeriodeIntervention}`)
      .then(function (response) {
        if (response.status === 200) {
          const action: RdvAction = {
            type: TYPES.RDV.CHECK_RDV,
            rdv: response.data
          }
          dispatch(action)
        }
      })
  }
}

const deleteOneCreneauForUser = (id: number) => {
  return (dispatch: DispatchRdv) => {
    CustomAxios
      .delete(baseUrl + routesApi.DELETE_RDV_USERCLIENT + "?id=" + id)
      .then((response) => {
        if (response.status === 200) {
          const actionRdv: RdvAction = {
            type: TYPES.RDV.DELETE,
            rdv: id,
          }
          dispatch(actionRdv)
        }
      });
  }
};

export const rdvAction = {
  getRdvByLieu,
  validerAbsenceClient,
  rdvHonorer,
  checkRdvCommande,
  deleteOneCreneauForUser,
} 