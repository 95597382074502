export const TYPES = {
  USER: {
    GET: "GET_USER",
    ADD: "ADD_USER",
    REMOVE: "REMOVE_USER",
    ACTIVATE: "ACTIVATE_USER",
    ADD_ONE_CRENEAUX_TO_USER: "ADD_ONE_CRENEAUX_TO_USER",
    MODIFY_ONE_CRENEAUX: "ADD_ONE_CRENEAUX_TO_USER",
    SET_TEL_USER: "SET_TEL_USER"
  },
  CLIENT: {
    GET: "GET_CLIENT",
    ADD: "ADD_CLIENT",
    DELETE: "DELETE_CLIENT",
    EDIT: "EDIT_CLIENT",
  },
  CURRENT_CLIENT: {
    ADD: "ADD_CURRENT_CLIENT"
  },
  UPLOADFILE: "UPLOAD_FILE",
  INTERVENTION: {
    GET: "GET_ALL_INTERVENTION",
    ADD: "ADD_INTERVENTION",
    REMOVE: "REMOVE_INTERVENTION",
    UPDATE: "UPDATE_INTERVENTION"
  },
  COMMANDE: {
    GET: "GET_ALL_COMMANDE"
  },
  LIEU: {
    GET: "GET_ALL_LIEUX",
    GET_BY_COMMANDE: "GET_BY_COMMANDE"
  },
  RDV: {
    GET: "GET_RDV",
    VALIDER: "VALIDER_RDV",
    ABSENCE_RDV: "ABSENCE_USERCLIENT_RDV",
    CHECK_RDV: "CHECK_RDV_COMMANDE",
    DELETE: "DELETE_RDV_USERCLIENT",
  },
  TYPE_MATERIEL: {
    GET: "GET_TYPE_MATERIEL_BY_CLIENT",
    ADD: "ADD_TYPE_MATERIEL_BY_CLIENT",
    EDIT: "EDIT_TYPE_MATERIEL_BY_CLIENT",
    DELETE: "DELETE_TYPE_MATERIEL_BY_CLIENT"
  },
  ROLE: {
    GET: "GET_ROLE"
  },
  STAT: {
    GET: "GET_STAT"
  },
  STAT_PERIODE_INTERVENTION: {
    GET: "GET_STAT_PERIODE_INTERVENTION"
  },
  PERIODES_INTERVENTION: {
    ADD: "ADD_PERIODE",
    GET: "GET_PERIODE",
    DELETE: "DELETE_PERIODE",
    VALIDER: "VALIDER_PERIODE"
  },
  TECHNICIENS: {
    GET: "GET_TECHNICIENS",
    ADD_CRENEAUX_TO_TECH: "ADD_CRENEAUX_TO_TECH"
  },
  CRENEAUX: {
    GET: "GET_CRENEAUX",
    ADD: "ADD_CRENEAUX",
    UPDATE: "UPDATE_CRENEAUX",
    GET_CRENEAUX_FOR_USER: "GET_CRENEAUX_FOR_USER"
  },
  CRUD: {
    GET: "CRUD_GET",
    ADD: "CRUD_ADD",
    UPDATE: "CRUD_UPDATE",
    DISABLE: "CRUD_DISABLE",
    DELETE: "CRUD_DELETE"
  },
  CONNEXION: {
    LOGGED: "GET_LOGGED"
  },
  LOADING : "GET_LOADING",
  ERROR : {
    SET : "SET_ERROR",
    DELETE : "DELETE_ERROR",
  }

}