import React from "react";
import { useSelector } from "react-redux";
import PlanningVisuelPeriodeFullCalendar from "../../components/planningPattern/PlanningVisuelPeriodeFullCalendar";
import { IUser } from "../../redux/Interfaces/typeUser";
import { IRedux } from "../../redux/reducers";


function TechnicienDashboard() {
	const userSelected: IUser = useSelector(
		(state: IRedux) => state?.userReduxState
	);
	return (
		<div className="container-main">
			<div className="container-white">
				<h2>Bonjour {userSelected.prenom} {userSelected.nom},</h2>
				<br />
				<h3>Voici votre planning résumant vos rendez-vous :</h3>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '4%' }}>
					<span
						style={{
							display: 'inline-block',
							width: 15,
							height: 15,
							borderRadius: '50%',
							backgroundColor: '#d9534f',
							marginRight: 5
						}}
					></span>
					<b>Absent lors du rendez-vous</b>
					<span
						style={{
							display: 'inline-block',
							marginLeft: 30,
							width: 15,
							height: 15,
							borderRadius: '50%',
							backgroundColor: 'hsl(18.75, 100%, 68.63%)',
							marginRight: 5
						}}
					></span>
					<b>Non renseigné</b>
					<span
						style={{
							display: 'inline-block',
							marginLeft: 30,
							width: 15,
							height: 15,
							borderRadius: '50%',
							backgroundColor: '#5cb85c',
							marginRight: 5
						}}
					></span>
					<b>Rendez-vous validé</b>
				</div>
				<PlanningVisuelPeriodeFullCalendar
					listOfCreneaux={userSelected.rdvs}
					duree={30}
					deletable={true}
					isDisplayedDashboardTech={true}
				/>
			</div>
		</div>
	);
}

export default TechnicienDashboard;
