import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import allocales from "@fullcalendar/core/locales-all";
import "./PlanningFullCalendar.scss";
import { ICreneau } from "../../redux/Interfaces/typeCreneaux";
import { calculDuree } from "../../services/CalculDebutFinJournee";
import tippy from "tippy.js";

const PlanningFullCalendarTest = ({
	setConfirmCreneauxToTechnicien,
	listOfCreneaux,
	periodesInterventionSelectionnee,
	technicien,
	addCreneauxToTechnician,
}: any) => {
	const dayjs = require("dayjs");
	const duration = require("dayjs/plugin/duration");
	dayjs.extend(duration);

	const [events, setEvents] = useState([]);

	//DUREE D'UN EVENT
	const [slotDuration, setSlotDuration] = useState<any>("01:30:00");
	const [slotDurationMinute, setSlotDurationMinute] = useState<number>(60);
	useEffect(() => {
		if (
			periodesInterventionSelectionnee?.typeIntervention?.duree !==
			undefined
		) {
			setSlotDuration(
				calculDuree(
					periodesInterventionSelectionnee?.typeIntervention?.duree
				)
			);
			setSlotDurationMinute(
				periodesInterventionSelectionnee?.typeIntervention?.duree
			);
		}
	}, [periodesInterventionSelectionnee]);

	const renderEventContent = (eventContent: any) => {
		if (
			eventContent.timeText === "12:00 - 13:00" ||
			eventContent.timeText === "13:00 - 14:00"
		) {
		}
		if (eventContent?.event._def.title === "créneau déjà réservé") {
			eventContent.backgroundColor = "red";
			eventContent.borderColor = "red";
		} else {
			eventContent.backgroundColor = "#90EE90";
			eventContent.borderColor = "#90EE90";
		}

		return (
			<b>
				{eventContent?.timeText}h :{" "}
				{(eventContent?.event._def.title).toLowerCase()}
			</b>
		);
	};

	const calendarRef = useRef<FullCalendar>(null!);
	let calendarAPI = calendarRef?.current?.getApi();

	//CALCUL PLAGE HORAIRE
	const [plageHoraire, setPlageHoraire] = useState<any>({
		heureDebut: "06:00",
		heureFin: "18:00",
	});

	//INIT DES DATA
	const [initData, setInitData] = useState<any>([]);
	useEffect(() => {
		setInitData(
			technicien?.creneauHoraires?.map((creneauReserve: any) => {
				var dpi = new Date(periodesInterventionSelectionnee?.dateDebut);
				var dcr = new Date(creneauReserve.dateDebut);
				if (dcr >= dpi) {
					return {
						title: "créneau déjà réservé",
						start: creneauReserve.dateDebut,
						end: creneauReserve.dateFin,
						id: creneauReserve.id.toString(),
					};
				}
				return {};
			})
		);
	}, [technicien]);

	useEffect(() => {
		setPlageHoraire({
			heureDebut: periodesInterventionSelectionnee?.startTimeRange?.toString(),
			heureFin: periodesInterventionSelectionnee?.endTimeRange?.toString(),
		})
	}, [periodesInterventionSelectionnee]);

	const [newData, setNewData] = useState<any>([]);

	// AJOUTER DES NOUVEAUX CRENEAUX DATECLICK
	const handleSelect = (arg: DateClickArg) => {
		let title = "nouveau créneau assigné";
		let calendar = arg.view.calendar;
		calendar.unselect();
		const creneauSelectionne: ICreneau[] = listOfCreneaux.filter(
			(creneau: ICreneau) => creneau.dateDebut == arg.dateStr.slice(0, 19)
		);
		var add = true;
		var startDateObj = new Date(arg.dateStr);
		var eventEndDateObj = new Date(creneauSelectionne[0].dateFin);
		events.forEach((event: any) => {
			var p_start = new Date(
				event._instance.range.start.toISOString().slice(0, 19)
			);
			var p_end = new Date(
				event._instance.range.end.toISOString().slice(0, 19)
			);
			if (
				((startDateObj > p_start && startDateObj < p_end) ||
					(eventEndDateObj > p_start && eventEndDateObj < p_end) ||
					startDateObj.getTime() == p_start.getTime() ||
					eventEndDateObj.getTime() == p_end.getTime()) &&
				event._def.title === "créneau déjà réservé"
			) {
				add = false;
			}
			if (
				event._def.title === "nouveau créneau assigné" &&
				p_start === startDateObj
			) {
				add = false;
			}
		});
		if (add == true) {
			calendar.addEvent({
				id: arg.dateStr,
				title,
				start: arg.dateStr,
				end: creneauSelectionne[0].dateFin,
				allDay: arg.allDay,
			});
			setNewData([...newData, arg.dateStr.slice(0, 19)]);
		}
	};

	const toISOStringWithTimezone = (date: any) => {
		const tzOffset = -date.getTimezoneOffset();
		const diff = tzOffset >= 0 ? "+" : "-";
		const pad = (n: any) => `${Math.floor(Math.abs(n))}`.padStart(2, "0");
		return (
			date.getFullYear() +
			"-" +
			pad(date.getMonth() + 1) +
			"-" +
			pad(date.getDate()) +
			"T" +
			pad(date.getHours()) +
			":" +
			pad(date.getMinutes()) +
			":" +
			pad(date.getSeconds()) +
			diff +
			pad(tzOffset / 60) +
			":" +
			pad(tzOffset % 60)
		);
	};

	// REMOVE CRENEAUX EVENTCLICK
	const handleEventClick = (clickInfo: any) => {
		if (clickInfo?.event._def.title === "nouveau créneau assigné") {
			//Au clic sur l'élement existant, on supprime le créneau
			clickInfo.event.remove();
			setNewData(
				newData.filter(
					(data: String) =>
						data != clickInfo.event._def.publicId.slice(0, 19)
				)
			);
		}
		if (clickInfo?.event._def.title === "créneau déjà réservé") {
			console.log("créneau déjà réservé");
		}
	};

	//REINITIALISER ET ASSIGNER TOUT
	const handleEvents = (arrayOfEventsBooked: any) => {
		setEvents(arrayOfEventsBooked);
	};

	//TOOLTIP
	const tooltip = (info: any) => {
		if (info.event._def.title === "créneau déjà réservé") {
			const creneau: ICreneau[] = technicien.creneauHoraires.filter(
				(creneau: ICreneau) => creneau.id == info.event.id
			);
			let content =
				creneau.length > 0
					? " <p>" +
					  creneau[0].lieu.nom +
					  "</p>" +
					  "<p>" +
					  creneau[0].lieu.adresse +
					  "</p>"
					: "";
			tippy(info.el, {
				allowHTML: true,
				interactive: true,
				content: content,
				trigger: "mouseenter",
			});
		}
	};

	return (
		<>
			{Object.keys(periodesInterventionSelectionnee).length !== 0 && (
				<div
					style={{
						background: "white",
						borderRadius: "20px",
						width: "105%",
					}}
				>
					<FullCalendar
						ref={calendarRef}
						plugins={[
							timeGridPlugin,
							dayGridPlugin,
							interactionPlugin,
							interactionPlugin,
						]}
						headerToolbar={{
							left: "addBtn,deleteBtn",
							center: "title",
							right: "prev,next",
						}}
						footerToolbar={{
							center: "submitBtn",
						}}
						timeZone={"local"}
						locales={allocales}
						locale={"FR"}
						height={850}
						expandRows={true}
						buttonText={{
							day: "Jour",
							prev: "Précédent",
							next: "Suivant",
							month: "Mois",
							week: "Semaine",
						}}
						customButtons={{
							submitBtn: {
								text: "CONFIRMER L'ASSIGNATION DES CRÉNEAUX AJOUTÉS",
								click: function () {
									let arrayOfCreneaux: ICreneau[] = [];
									newData.map((d: String) => {
										listOfCreneaux.map(
											(creneau: ICreneau) => {
												if (creneau.dateDebut === d) {
													arrayOfCreneaux.push(
														creneau
													);
												}
											}
										);
									});
									addCreneauxToTechnician(technicien.id, {
										creneaux: arrayOfCreneaux,
									});
									setConfirmCreneauxToTechnicien(true);
								},
							},
							deleteBtn: {
								text: "RÉINITIALISER",
								click: function () {
									events.forEach((event: any) => {
										if (
											event._def.title !==
											"créneau déjà réservé"
										) {
											event.remove();
											setNewData([]);
										}
									});
								},
							},
							addBtn: {
								text: "ASSIGNER TOUS LES CRÉNEAUX",
								//Je crée une boucle qui ajoute 24 créneaux sur une date
								//Puis je crée une boucle sur le nombre de jours d'intervention qui copie pour chaque jour les 24 créneaux à la date suivante
								click: function () {
									events.forEach((event: any) => {
										if (
											event.title ===
											"nouveau créneau assigné"
										) {
											event.remove();
										}
									});
									var view = calendarAPI.view;
									let calendarApi = view.calendar;
									var startDateObj = new Date(
										view.activeStart.getFullYear() +
											"-" +
											(view.activeStart.getMonth() + 1) +
											"-" +
											view.activeStart.getDate() +
											" " +
											plageHoraire.heureDebut
									);
									var endDateObj = new Date(
										view.activeEnd.getFullYear() +
											"-" +
											(view.activeEnd.getMonth() + 1) +
											"-" +
											(view.activeEnd.getDate() - 1) +
											" " +
											plageHoraire.heureFin
									);
									let array: any = [];
									while (startDateObj < endDateObj) {
										var add = true;
										var todayEndDateObj = new Date(
											startDateObj.getFullYear() +
												"-" +
												(startDateObj.getMonth() + 1) +
												"-" +
												startDateObj.getDate() +
												" " +
												plageHoraire.heureFin
										);
										if (startDateObj >= todayEndDateObj) {
											add = false;
										}
										var eventEndDateObj = new Date(
											startDateObj
										);
										eventEndDateObj.setMinutes(
											eventEndDateObj.getMinutes() +
												slotDurationMinute
										);
										var ddpi = new Date(
											periodesInterventionSelectionnee?.dateDebut
										);
										var dfpi = new Date(
											periodesInterventionSelectionnee?.dateFin
										);
										if (
											startDateObj < ddpi ||
											eventEndDateObj > dfpi
										) {
											add = false;
										}
										events.forEach((event: any) => {
											var p_start = new Date(
												event._instance.range.start
													.toISOString()
													.slice(0, 19)
											);
											var p_end = new Date(
												event._instance.range.end
													.toISOString()
													.slice(0, 19)
											);
											if (
												((startDateObj > p_start &&
													startDateObj < p_end) ||
													(eventEndDateObj >
														p_start &&
														eventEndDateObj <
															p_end) ||
													startDateObj.getTime() ==
														p_start.getTime() ||
													eventEndDateObj.getTime() ==
														p_end.getTime()) &&
												event._def.title ===
													"créneau déjà réservé"
											) {
												add = false;
											}
										});
										if (add == true) {
											calendarApi.addEvent({
												id: startDateObj.toISOString(),
												title: "nouveau créneau assigné",
												start: startDateObj.toISOString(),
												end: eventEndDateObj.toISOString(),
												allDay: false,
											});
											array.push(
												toISOStringWithTimezone(
													startDateObj
												).slice(0, 19)
											);
										}
										startDateObj.setMinutes(
											startDateObj.getMinutes() +
												slotDurationMinute
										);
									}
									setNewData(array);
								},
							},
						}}
						initialView="timeGridWeek"
						validRange={() => {
							return {
								start: new Date(
									periodesInterventionSelectionnee?.dateDebut
								),
								end: new Date(
									periodesInterventionSelectionnee?.dateFin
								),
							};
						}}
						selectable={true}
						editable={false}
						dayHeaderFormat={{
							week: "long",
							day: "numeric",
							month: "long",
						}}
						weekends={false}
						eventOverlap={false}
						selectOverlap={false}
						allDaySlot={false}
						slotDuration={slotDuration}
						slotMinTime={plageHoraire.heureDebut}
						slotMaxTime={plageHoraire.heureFin}
						events={initData}
						eventContent={renderEventContent}
						dateClick={handleSelect}
						eventClick={handleEventClick}
						eventsSet={handleEvents}
						eventDidMount={tooltip}
					/>
					{/* } */}
				</div>
			)}
		</>
	);
};

export default PlanningFullCalendarTest;
