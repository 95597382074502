import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IModale } from "../model/Modale";
import { Grid } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "55%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "#fff",
  boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.15)",
  p: 4,
  borderRadius: 3,
};

const Modale: React.FunctionComponent<IModale> = ({
  title,
  contenu,
  actionValider,
  openModale,
  fonctionOpenModale,
}) => {
  return (
    <div>
      <Modal
        open={openModale}
        onClose={() => {
          fonctionOpenModale(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            textAlign="center"
            fontWeight="bold"
          >
            {title}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 4, fontSize: 18 }}
            textAlign="center"
          >
            {contenu}
          </Typography>

          <Grid
            container
            justifyContent="space-between"
            mt={4}
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => actionValider()}
              >
                Valider
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => {
                  fonctionOpenModale(false);
                }}
              >
                Fermer
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default Modale;