import {
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Client } from "../../../redux/Interfaces/typeClient";
import { IUser } from "../../../redux/Interfaces/typeUser";
import { IRedux } from "../../../redux/reducers";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { bindActionCreators } from "redux";
import { currentClientAction } from "../../../redux/actions/CurrentClientActions";
import { useNavigate } from "react-router-dom";
import LiensInternes from "../../../services/LiensInternes";

export default function NavBarClient() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { addCurrentClient } = bindActionCreators(
		currentClientAction,
		dispatch
	);

	const user: IUser = useSelector((state: IRedux) => state?.userReduxState);
	const currentClient: Client = useSelector(
		(state: IRedux) => state?.currentClient
	);

	const [client, setClient] = useState<Client[]>();
	useEffect(() => {
		setClient(user.client);
	}, [user]);
	const [clientSelectionne, setClientSelectionne] = useState<Client>();
	useEffect(() => {
		if (user.client.length > 0) {
			setClientSelectionne(currentClient);
		}
	}, [currentClient, user]);

	return (
		<>
			<h4 style={{ textAlign: "center" }}>Client(s)</h4>
			<List>
				{client?.map((client: Client, index: number) => (
					<ListItem
						key={index}
						disablePadding
						onClick={() => {
							addCurrentClient(client);
							navigate(
								LiensInternes.ADMIN.BASE +
								LiensInternes.ADMIN.TABLEAU_DE_BORD
							);
						}}
					>
						{!client.delete &&
							<ListItemButton>
								<ListItemText
									primary={client.nom}
									sx={{
										textAlign: "center",
										padding: "0",
									}}
								/>
								<ListItemIcon>
									{client.id === clientSelectionne?.id && (
										<CheckCircleIcon color="success" />
									)}
								</ListItemIcon>
							</ListItemButton>
						}
					</ListItem>
				))}
			</List>
		</>
	);
}
