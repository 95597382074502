import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IModaleWithInput } from "../../model/ModaleWithInput";
import { Divider, Grid, TextField } from "@mui/material";
import { capitalizeFirstLetter } from "../../services/ReformatString";
import RadioPattern from "../formPatterns/radioPattern/RadioPattern";
import { useDispatch, useSelector } from "react-redux";
import { IRedux } from "../../redux/reducers";
import CheckboxPattern from "../formPatterns/checkboxPattern/CheckboxPattern";
import "./ModaleWithInput.scss";
import PasswordFormPattern from "../formPatterns/passwordPattern/passwordFormPattern";
import { ErrorActions } from "../../redux/actions/errorActions";
import { bindActionCreators } from "redux";

const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "55%",
	transform: "translate(-50%, -50%)",
	width: "50%",
	bgcolor: "#fff",
	boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.15)",
	p: 4,
	borderRadius: 3,
};

const ModaleWithInput: React.FunctionComponent<IModaleWithInput> = ({
	title,
	idDataSelectionne,
	tableauDeValeur,
	openModale,
	fonctionOpenModale,
	type,
	actionValider,
}) => {
	const dispatch = useDispatch();
	const { setError } = bindActionCreators(ErrorActions, dispatch);

	const [data, setData] = React.useState<any>({});

	React.useEffect(() => {
		idDataSelectionne && Object.assign(data, { id: idDataSelectionne });
		tableauDeValeur.length > 0 &&
			tableauDeValeur?.map((val: any) => {
				Object.assign(data, { [val.label]: val.value });
			});
	}, [tableauDeValeur]);

	//DATA ROLES BDD POUR LES USER COM
	const allRoles = useSelector((state: IRedux) => state.roleReduxState);
	const [role, setRole] = React.useState<any>([]);
	React.useEffect(() => {
		if (allRoles.length > 0) {
			if (type === "role") {
				role.push({
					value: allRoles[2].id,
					label: allRoles[2].name,
				});
				role.push({
					value: allRoles[4].id,
					label: allRoles[4].name,
				});
			} else {
				role.push({
					value: allRoles[0].id,
					label: allRoles[0].name,
				});
				role.push({
					value: allRoles[1].id,
					label: allRoles[1].name,
				});
			}
		}
	}, [allRoles]);

	const clients = useSelector((state: any) => state.clientReduxState);
	const [arrayClient, setArrayClient] = React.useState<any>([]);
	React.useEffect(() => {
		clients.map((c: any) =>
			arrayClient.push({
				value: c.id,
				label: c.nom,
			})
		);
	}, [clients]);

	return (
		<div>
			<Modal
				open={openModale}
				onClose={() => {
					fonctionOpenModale(false);
				}}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography
						id="modal-modal-title"
						variant="h5"
						component="h2"
					>
						{title}
					</Typography>

					{type === "modify-mdp" ? (
						<PasswordFormPattern
							type="changement"
							id={idDataSelectionne}
						/>
					) : (
						<>
							<Box
								sx={{
									padding: "20px",
									marginTop: "2%"
								}}
							>
								<Grid
									container
									rowSpacing={1}
									columnSpacing={{ xs: 1, sm: 2, md: 3 }}
								>
									<Grid xs={6}>
										{tableauDeValeur.length > 0 &&
											tableauDeValeur?.map(
												(valeur: any) => {
													if (
														typeof valeur.value ===
														"string" &&
														type !== "role"
													) {
														//INPUT STRING
														return (
															<TextField
																sx={{
																	padding:
																		"10px",
																	width: "100%",
																}}
																label={capitalizeFirstLetter(
																	valeur.label
																)}
																type={
																	valeur.label ===
																		"mot de passe"
																		? "password"
																		: "text"
																}
																defaultValue={
																	valeur.value
																}
																onChange={(
																	e
																) => {
																	tableauDeValeur?.filter(
																		(
																			value: any
																		) =>
																			value.label ===
																			valeur.label
																	);
																	setData({
																		...data,
																		[valeur.label]:
																			e
																				.target
																				.value,
																	});
																}}
															/>
														);
													}
													if (
														valeur.label ===
														"role" &&
														type === "role"
													) {
														return (
															<>
																<Divider />
																<RadioPattern
																	data={role}
																	defaultValue={
																		valeur
																			?.value[0]
																			? valeur
																				?.value[0]
																				.id
																			: allRoles[0]
																				.name
																	}
																	setData={
																		data
																	}
																	labelData={
																		valeur.label
																	}
																/>
															</>
														);
													}
												}
											)}
									</Grid>

									<Grid xs={6}>
										{tableauDeValeur.length > 0 &&
											tableauDeValeur?.map(
												(valeur: any) => {
													if (
														valeur.label ===
														"role" &&
														type !== "role"
													) {
														return (
															<>
																<Divider />
																<RadioPattern
																	data={role}
																	defaultValue={
																		valeur
																			?.value[0]
																			? valeur
																				?.value[0]
																				.id
																			: allRoles[0]
																				.name
																	}
																	setData={
																		data
																	}
																	labelData={
																		valeur.label
																	}
																/>
															</>
														);
													}
													if (
														valeur.label ===
														"client" &&
														type !== "role"
													) {
														return (
															<>
																<Divider />
																<CheckboxPattern
																	data={
																		arrayClient
																	}
																	dataBdd={
																		valeur.value
																	}
																	setData={
																		data
																	}
																	labelData={
																		valeur.label
																	}
																/>
															</>
														);
													}

													//USER COM
												}
											)}
									</Grid>
								</Grid>
								{tableauDeValeur.length === 0 && (
									<Typography
										id="modal-modal-description"
										sx={{ mt: 2 }}
									>
										Aucune valeur
									</Typography>
								)}
							</Box>
							<Grid
								container
								justifyContent="space-between"
								mt={2}
							>
								<Grid item>
									<Button
										variant="contained"
										onClick={() => {
											let error = Object.values(
												data
											).filter((d: any) => d === "");
											console.log("error", error);
											if (error.length === 0) {
												console.log("data", data)
												actionValider(data, type);
												fonctionOpenModale(false);
											} else {
												setError(
													"Il manque un champ obligatoire",
													409
												);
											}
										}}
									>
										Valider
									</Button>
								</Grid>
								<Grid item>
									<Button
										variant="contained"
										onClick={() => {
											fonctionOpenModale(false);
										}}
									>
										Fermer
									</Button>
								</Grid>
							</Grid>
						</>
					)}
				</Box>
			</Modal>
		</div>
	);
};

export default ModaleWithInput;
