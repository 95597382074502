export const routesApi = {
	GET_REFRESH: "url",
	USERS: "user",
	LOGIN: "user/service/login",
	MODIFY_MDP: "user/service/modifier-mot-de-passe",
	EMAIL_RESET_MDP: "/user/service/email-reset-password",
	RESET_MDP: "user/service/reset-password",
	DASHBOARD: "user/dashboard",
	GET_CLIENT: "client",
	GET_REFRESH_TOKEN: "user/service/refresh-token",

	UPLOAD_FILE: "upload-csv-file",
	UPLOAD_FILE_LIEU: "upload-csv-file/lieu",
	GET_LIST_CLIENT: "upload-csv-file/liste-client",

	GET_INTERVENTIONS: "type-intervention",
	
	ACTIVATION: "user/service/activation",
	GET_COMMANDES: "commande",
	GET_COMMANDES_CLIENT: "commande/get-by-client",

	GET_LIEUX: "lieu",
	GET_LIEUX_BY_CLIENT: "lieu/client",
	GET_LIEUX_BY_COMMANDES: "lieu/commande",

	TYPE_MATERIEL: "type-materiel",
	GET_TYPE_MATERIEL_BY_CLIENT: "type-materiel/by-client",
	DELETE_TYPE_MATERIEL: "type-materiel/delete",

	GET_PERIODE_INTERVENTION: "periode-intervention",
	GET_ONE_PERIODE_INTERVENTION: "periode-intervention/get-one",
	ADD_PERIODE_INTERVENTION: "periode-intervention/multiple-client",
	ADD_PERIODE_INTERVENTION_RAPPEL: "periode-intervention/periode-de-rappel",
	GET_PERIODE_INTERVENTION_LIEU: "periode-intervention/by-lieu",
	VALIDER_PERIODE_INTERVENTION: "periode-intervention/valider",
	DELETE_PERIODE_INTERVENTION: "periode-intervention/delete",

	GET_TECHNICIENS: "user/technicien",
	GET_USER_COM: "user/user-com-net",
	USER_CLIENT: "user/client",

	GET_CRENEAUX: "creneau-horaire/periode-intervention",
	ADD_CRENEAUX: "creneau-horaire/ajout-techniciens",
	DELETE_TECH_CRENEAU: "creneau-horaire/remove-techniciens",
	GET_CRENEAUX_BY_RDV_AND_LIEU: "creneau-horaire/rdv-par-lieu",

	GET_ROLE: "role/with-deleted",

	GET_RDV_BY_LIEU: "creneau-horaire/rdv-par-lieu",

	VALIDER_RDV: "rdv/honorer",
	ABSENCE_CLIENT_RDV: "rdv/absence-client",
	CHECK_RDV_COMMANDE: "rdv/check-rdv-commande",
	DELETE_RDV_USERCLIENT: "rdv/delete",

	SEARCH_USER_CLIENT: "user/client/search",

	GET_STATISTIQUES: "statistiques/by-client",
	GET_STATISTIQUES_PERIODE_INTER: "statistiques/by-periode-intervention",

	UPDATE_TECHNICIEN: "user/technicien",
	UPDATE_CLIENT: "client",
	UPDATE_USERCOM: "user/user-com-net",
	UPDATE_USERCLIENT: "user/client",
	GET_CRENEAUX_USER: "creneau-horaire/creneaux-pour-rdv",
	ADD_ONE_RDV: "rdv",
	SET_TEL_USER: "user/telephone",
};
